import React, { Component } from "react";
import Header from "./components/university/universityHeader";
import AnimationSlider from './components/university/animationSlider';
import NextCourse from './components/university/nextCourse';
import UniversityService from './components/university/universityServices';
import UniversityEnrollment from './components/university/universityTrainingMethods';
import UniversityCourses from "./components/university/universitySpecialization";
import UniversityEvents from "./components/university/universityEvents";
import UniversityGallery from "./components/university/universityGallery";
import Footer from "./components/university/footerTraining";

class App extends Component {

  render() {
    return (
      <div>
        <Header />
        <AnimationSlider />
        <NextCourse /><br/><br/><br/>
        <UniversityService /><br/><br/><br/>
        <UniversityEnrollment /><br/><br/><br/>
        <UniversityCourses /><br/><br/><br/>
        <UniversityEvents /><br/><br/>
        <UniversityGallery /><br/>
        <Footer />
      </div>
    );
  }
}

export default App;
