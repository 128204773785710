import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import img1 from '../../assets/img2.png';
import img2 from '../../assets/img1.png';
import img3 from '../../assets/img3.png';
import img4 from '../../assets/img4.png';
import img5 from '../../assets/img5.png';
import img6 from '../../assets/img6.png';
import img7 from '../../assets/img7.png';
import img8 from '../../assets/img8.png';

class universityGallery extends Component {
  
    styleSmall(){
        return ({
           border:'px solid red',
           width:'100%',
           height: '250px'
        });
     } 

    render() {

        const images = 
            [
                {
                    src: img2,
                    thumbnail: img2,
                    thumbnailWidth: 600,
                    thumbnailHeight: 320,
                    // caption: "8H (gratisography.com)",
                    
                },
                {
                    src: img1,
                    thumbnail: img1,
                    thumbnailWidth: 500,
                    thumbnailHeight: 190,
                    // caption: "286H (gratisography.com)",
                },
                {
                    src: img3,
                    thumbnail: img3,
                    thumbnailWidth: 800,
                    thumbnailHeight: 148,
                    // caption: "315H (gratisography.com)",
                
                },
                {
                    src: img4,
                    thumbnail: img4,
                    thumbnailWidth: 500,
                    thumbnailHeight: 213,
                    // caption: "201H (gratisography.com)",
                
                },
                {
                    src: img5,
                    thumbnail: img5,
                    thumbnailWidth: 800,
                    thumbnailHeight: 320,
                    // caption: "Big Ben (Tom Eversley - isorepublic.com)",
                    
                },
                {
                    src: img6,
                    thumbnail: img6,
                    thumbnailWidth: 600,
                    thumbnailHeight: 113,
                    // caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)",
                  
                },
                {
                    src: img7,
                    thumbnail: img7,
                    thumbnailWidth: 800,
                    thumbnailHeight: 320,
                    // caption: "Wood Glass (Tom Eversley - isorepublic.com)",
                 
                },
                {
                    src: img8,
                    thumbnail: img8,
                    thumbnailWidth: 600,
                    thumbnailHeight: 213,
                    // caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
                    
                },
                {
                    src: img3,
                    thumbnail: img3,
                    thumbnailWidth: 500,
                    thumbnailHeight: 213,
                    // caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
                    
                }
            ]
        
        return (
            <div style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                border: "1px solid #ddd",
                overflow: "auto",
                textAlign: "center",
                background: "white"
            }}>
                <Gallery
                    images={images} thumbnailStyle={this.styleSmall}
                    enableImageSelection={false} rowHeight={250}
                />
            </div>
        );
    }
}

export default universityGallery; 