// import React, { Component } from "react";
// import { NavLink } from 'react-router-dom';
// import skillrary_logo from '../../assets/skillrary_logo.jpg';

// class Header extends Component {
	
// 	render(){
//     return (
//       <div>
     
//     <style jsx>{`
//           .searchCartIcon{
//             padding: 15px;
//           }
//           .searchCartIcon a{
//             color: white;
//           }
//           .navbar-light .navbar-toggler{ 
//             border-color: white; 
//             float: right;
//           } 
//           .navbar-light .navbar-toggler:focus{
//             outline: none;
//           }  
//           .navbar-light .navbar-toggler-icon { 
//             background-image: url( "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250,250,250)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); 
//           } 
//           .activeHeading {
//             background: black;
//             // border-radius: 5px;
//           }
//           .skillrary_logo{
//             height: 72px;
//             width: 100%;
//             padding: 0;
//             margin: 0;
//           }
// 	      	.bg-light{
//             padding: 0;
//             margin: 0;
//             // position: fixed;
//             background-color: #33A478 !important;
//             // background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164,	120) , rgba(0,0,0));
//             width: 100%;
//             z-index: 999999;
// 	      	}
// 	      	#containerFluid{
// 	      		padding-right: 0px;
// 	      		padding-left: 0px;
// 	      	}
// 	      	.nav-bar{
// 	      		padding: 25px 20px !important;
// 	      	}
// 	      	.nav-item{
//             text-decoration: none;
//             font-size: 15px;
//             // padding: 0px 30px;
//             color: black;
//           }
//           .nav-link-header:hover{
//             background: black;
//             // border-radius: 5px;
//           }
//           .categoryLink:hover{
//             background: transparent !important;
//           }
// 	      	.dropbtn a{
// 	      		font-weight: 600;
// 	      		font-size: 20px;
// 	      		color: black;
// 	      	}
// 	      	.nav-item-categories{
// 	      		font-weight: 600;
// 	      		font-size: 20px;
// 	      		padding: 25px 20px;
// 	      	}
// 	      	.nav-item .nav-link{
// 	      		font-weight: 600;
//           }
//           .nav-link-header{
//               color: white !important;
//           }
// 	      	.nav-item-categories .nav-link{
// 	      		color: black !important;
// 	      	}
// 	      	.navbar-nav{
// 	      		text-align: center;
//           }
//           .nav-right{
//             float: right;
//           }
//           .example {
//             padding: 1px 10px;
//             font-size: 15px;
//             border: 1px solid black;
//             border-radius: 4px 0px 0px 4px;
//             float: left;
//             width: 80%;
//             background: white;
//           }
//           .example:focus  {
//             outline: none;
//           }
//           .searchButton {
//             float: right;
//             padding: 0px 6px;
//             background: transparent;
//             color: black;
//             font-size: 17px;
//             border: 1px solid transparent;
//             border-radius: 0px 4px 4px 0px;
//             border-left: none;
//             cursor: pointer;
//             margin-top: 3px;
//             height: 28px;
//             position: absolute;
//             display: block;
//           }
//           .searchButton:focus{
//               outline: none;
//           }
//           .example::after {
//             content: "";
//             clear: both;
//             display: table;
//           }
//           .dropdown .dropbtn {
//             font-size: 16px;  
//             border: none;
//             outline: none;
//             color: white;
//             padding: 0px 9px;
//             background-color: inherit;
//             font-family: inherit;
//             margin: 0;
//           }
//           .dropdown-content {
//             display: none;
//             position: absolute;
//             background-color: #f9f9f9;
//             min-width: 160px;
//             box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
//             z-index: 1;
//           }
//           .dropdown-content a {
//             color: black;
//             padding: 12px 16px;
//             text-decoration: none;
//             display: block;
//             text-align: left;
//           }
//           .dropdown:hover .dropdown-content {
//             display: block;
//           }
//           #searchFaIcon{
//             margin-top: -9px;
//               position: absolute;
//           }
//           @media only screen and (max-width: 600px){
//             .bg-light{
//               padding: 10px;
//               margin: 0;
//               position: fixed;
//               background-color: #33A478 !important;
//               // background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164,	120) , rgba(0,0,0));
//               width: 100%;
//               z-index: 999999;
//             }
//             #dialog{
//               display: none;
//             }
//             .nav-item{
//               text-decoration: none;
//               font-size: 15px;
//               padding: 0px 30px;
//               color: black;
//               margin-bottom: 10px;
//             }
//             .nav-search-bar{
//               margin-left: 31px;
//             }
//             .modalDialogContainer{
//               display: none;
//             }
//           }
//           @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
//             .modalDialogContainer{
//               display: none;
//             }
//             .nav-search-bar{
//               left: 30%;
//               transform: translateX(30%)
//             }
//           }
//           @media (min-width: 992px){
//             .navbar-expand-lg .navbar-nav .nav-link {
//                 padding: 25px 20px;
//             }
//           }
//           @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
//             .nav-item{
//               font-weight: 600;
//               font-size: 20px;
//               padding: 3px 0px;
//               color: black;
//               margin-bottom: 10px;
//             }
//             .modalDialogContainer{
//               display: none;
//             }
//           }
//       	`}
//       </style>
	  
//       <div className="container-fluid" id="containerFluid">
//         <nav className="navbar navbar-expand-lg bg-light navbar-light">
//         	<a class="navbar-brand" href="https://live.skillrary.com" style={{margin: '0',padding: '0'}}><img src={skillrary_logo} className="skillrary_logo"/></a>
//           <div className="container">
//             <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
//               <span className="navbar-toggler-icon"></span>
//             </button>
          
//             <div className="collapse navbar-collapse" id="collapsibleNavbar">
//               <ul className="navbar-nav">
//                 <li className="nav-item">
//                   <NavLink to="/" exact activeClassName="activeHeading"  className="nav-link nav-link-header">Home</NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink to="/About" activeClassName="activeHeading"  className="nav-link nav-link-header">About</NavLink>
//                 </li>
//               </ul>
//               <ul className="navbar-nav ml-auto">
//                 <li className="searchCartIcon"><a href=""><i className="fa fa-search"></i></a></li>
//                 <li className="searchCartIcon"><a href=""><i className="fa fa-shopping-cart"></i></a></li>
//               </ul>
//             </div>  

//           </div>
//         </nav>
//       </div>
//       </div>
//     );
//   }
// }

// export default Header;




import React, { Component } from "react";
import { NavLink , Link} from 'react-router-dom';
import skillrary_logo from '../../assets/skillrary_logo.jpg';
import Categories from '../university/categoriesUniversity';

class Header extends Component {
	render(){
    return (
      <div>
     
    <style jsx>
      {`
          .registerBtn{
            color: #33A478 !important;
          }
          .navbar-light .navbar-toggler{ 
            border-color: white; 
            float: right;
          } 
          .navbar-light .navbar-toggler:focus{
            outline: none;
          }  
          .navbar-light .navbar-toggler-icon { 
            background-image: url( "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250,250,250)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); 
          } 
          .activeHeading {
            background: black;
          }
          .skillrary_logo{
            height: 72px;
            padding: 0;
            margin: 0;
          }
          .bg-light{
            padding: 0;
            margin: 0;
            background-color: black !important;
            width: 100%;
            z-index: 999999;
          }
          #containerFluid{
            padding-right: 0px;
            padding-left: 0px;
          }
          .nav-bar{
            padding: 25px 20px !important;
          }
          .nav-item{
            text-decoration: none;
            font-size: 15px;
            color: black;
          }
          .nav-link-header:hover{
            background: black;
          }
          .categoryLink:hover{
            background: transparent !important;
          }
          .dropbtn a{
            font-weight: 600;
            font-size: 20px;
            color: black;
          }
          .nav-item-categories{
            font-weight: 600;
            font-size: 20px;
            padding: 25px 20px;
          }
          .nav-item .nav-link{
            font-weight: 600;
          }
          .nav-link-header{
              color: white !important;
          }
          .nav-item-categories .nav-link{
            color: black !important;
          }
          .navbar-nav{
            text-align: center;
          }
          .nav-right{
            float: right;
          }
          .example {
            padding: 1px 10px;
            font-size: 15px;
            border: 1px solid black;
            border-radius: 4px 0px 0px 4px;
            float: left;
            width: 80%;
            background: white;
          }
          .example:focus  {
            outline: none;
          }
          .searchButton {
            float: right;
            padding: 0px 6px;
            background: transparent;
            color: black;
            font-size: 17px;
            border: 1px solid transparent;
            border-radius: 0px 4px 4px 0px;
            border-left: none;
            cursor: pointer;
            margin-top: 3px;
            height: 28px;
            position: absolute;
            display: block;
          }
          .searchButton:focus{
              outline: none;
          }
          .example::after {
            content: "";
            clear: both;
            display: table;
          }
          .dropdown .dropbtn {
            font-size: 16px;  
            border: none;
            outline: none;
            color: white;
            padding: 0px 9px;
            background-color: inherit;
            font-family: inherit;
            margin: 0;
          }
          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
          }
          .dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            text-align: left;
          }
          .dropdown:hover .dropdown-content {
            display: block;
          }
          #searchFaIcon{
            margin-top: -9px;
              position: absolute;
          }
          .navbar-expand-lg .navbar-nav .nav-link {
            padding: 20px 33px;
            font-size: 20px;
        }

        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 50% !important;
          transform: translateX(-50%) !important;
          z-index: 1000;
          display: none;
          float: left;
          min-width: 10rem;
          padding: .5rem 0;
          margin: .125rem 0 0;
          font-size: 1rem;
          color: #212529;
          text-align: left;
          list-style: none;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid rgba(0,0,0,.15);
          border-radius: .25rem;
      }
      .serviceButton{
				background: transparent;
				border: 1px solid transparent;
			}
			.servicesMenu{
				margin-left: -30px;
			}
			.serviceButton:focus{
				outline: none !important;
			}
			.dropdown-item:active{
        background: #ccc;
      }

			@media only screen and (max-width: 600px){
        .registerBtn{
          color: black !important;
        }
				.serviceButton{
					left: 50%;
					position: relative;
					transform: translateX(-50%);
				}
				.servicesMenu{
					margin-left: 0px;
				}

            .dropdown-menu {
              position: absolute;
              top: 100%;
              left: initial !important;
              transform: initial !important;
              z-index: 1000;
              display: none;
              float: left;
              min-width: 10rem;
              padding: .5rem 0;
              margin: .125rem 0 0;
              font-size: 1rem;
              color: #212529;
              text-align: left;
              list-style: none;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid rgba(0,0,0,.15);
              border-radius: .25rem;
            }
            .bg-light{
              padding: 10px;
              margin: 0;
              position: fixed;
              background-color: #33A478 !important;
              width: 100%;
              z-index: 999999;
            }
            #dialog{
              display: none;
            }
            .nav-item{
              text-decoration: none;
              font-size: 15px;
              padding: 0px 30px;
              color: black;
              margin-bottom: 10px;
            }
            .nav-search-bar{
              margin-left: 31px;
            }
            .modalDialogContainer{
              display: none;
            }
          }
          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
            .modalDialogContainer{
              display: none;
            }
            .nav-search-bar{
              left: 30%;
              transform: translateX(30%)
            }
          }
          // @media (min-width: 992px){
          //   .navbar-expand-lg .navbar-nav .nav-link {
          //       padding: 20px 6px;
          //       font-size: 20px;
          //   }
          // }
          @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
            .nav-item{
              font-weight: 600;
              font-size: 20px;
              padding: 3px 0px;
              color: black;
              margin-bottom: 10px;
            }
            .modalDialogContainer{
              display: none;
            }
            .navbar-expand-lg .navbar-nav .nav-link {
              padding: 20px 40px;
              font-size: 20px;
          }
          }
      	`}
      </style>

      <div className="container-fluid" id="containerFluid">
        <nav className="navbar navbar-expand-lg bg-light navbar-light">
        	<a class="navbar-brand" href="https://live.skillrary.com" style={{ padding: '0', margin: '0'}}><img src={skillrary_logo} className="skillrary_logo"/></a>
        	  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
			        <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item">
                  <div className="dropdown">
                      <button className="dropbtn">
                        <a className="nav-link nav-link-header categoryLink" href="#" style={{ fontSize: '20px' }}><i style={{ marginRight: '10px' }} class="fa fa-bars" aria-hidden="true"></i>CATEGORIES </a>
                      </button>
                      <div className="dropdown-content">
                      <Categories />
                      </div>
                  </div> 
	              </li>  */}
				        <li className="nav-item">
                  	<NavLink to="/" className="nav-link nav-link-header">HOME</NavLink>
                </li>
                {/* <li className="nav-item">
	                  <NavLink to="/" className="nav-link nav-link-header">UNIVERSITIES</NavLink>
			 	        </li>     */}

                 <li className="nav-item dropdown">
                  <a className="nav-link nav-link-header dropdown-toggle" data-toggle="dropdown" href="#">UNIVERSITIES</a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#">Ramaiah Institute of Technology</a>
                    <a className="dropdown-item" href="#">HMS Institute of Technology</a>
                    <a className="dropdown-item" href="#">Seshadripuram Educational Trust</a>
                    <a className="dropdown-item" href="#">Sambhram Institute of Technology</a>
                    <a className="dropdown-item" href="#">JSS Academy of Technical Education</a>
                    <a className="dropdown-item" href="#">KS Group of Institution</a>
                    <a className="dropdown-item" href="#">Bangalore Institute of Technology</a>
                    <a className="dropdown-item" href="#">Acharya Institutes</a>
                  </div>
                </li>

                <li className="nav-item">
					        <a href="https://skillrary.com/category/skillx" className="nav-link nav-link-header">SKILLX</a>
                </li>
                <li className="nav-item">
                  <a href="https://skillrary.com/contact-us" className="nav-link nav-link-header">CONTACT US</a>
                </li>
                <li className="nav-item">
					        <a href="https://skillrary.com/user/register" className="nav-link registerBtn" style={{ letterSpacing: '2px', fontWeight: '600' }}>REGISTER</a>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                  <button type="button" className="nav-link nav-link-header dropdown-toggle serviceButton" data-toggle="dropdown">
                    Services
                  </button>
                  <div className="dropdown-menu servicesMenu">
                    <a className="dropdown-item" href="https://compiler.skillrary.com/" target="_blank">Compiler</a>
                    <a className="dropdown-item" href="https://live.skillrary.com/" target="_blank">Skillrary Live</a>
                    <a className="dropdown-item" href="https://assess.skillrary.com/" target="_blank">Skillrary Assessments</a>
                    <a className="dropdown-item" href="https://business.skillrary.com/" target="_blank">Skillrary Business</a>
                    <a className="dropdown-item" href="https://code.skillrary.com/" target="_blank">Skillrary Code test</a>
                  </div>
                  </div>
                </li>
              </ul>
            </div>  
          </nav>
      </div>
      </div>
    );
  }
}

export default Header;