import React, { Component } from 'react';
import dateFormat from 'dateformat';
import cap from '../../assets/cap.png';

class nextCourse extends Component{
    constructor(props){
        super(props);
        this.state = {
            workshopDate : '',
            workshopName : '',
            workshopTime : ''
        }
    }
     
    componentDidMount(){
        this.getTimeDate();
    }

    getTimeDate = () => {
	    fetch("https://live.skillrary.com/live-courses/index.php/api/v1/university", {
            method: 'GET',
            headers: {'Content-Type':'application/json'},
	    })
            .then(res => res.json())
            .then(
	        (result) => {
                this.setState({
                        workshopDate : result[0].workshop_startdate,
                        workshopName : result[0].workshop_name,
                        workshopTime : result[0].workshop_starttime
                })
                this.getTime();
	        },
	        (error) => {
	          this.setState({
	            isLoaded: true,
	            error
	          });
	        }
        )
    }

    getTime = () => {
        var deadline = new Date(this.state.workshopDate).getTime(); 
        var x = setInterval(function() { 
        var now = new Date().getTime(); 
        var t = deadline - now; 
        var days = Math.floor(t / (1000 * 60 * 60 * 24)); 
        var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60)); 
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)); 
        var seconds = Math.floor((t % (1000 * 60)) / 1000); 

        if(days < 10){
            document.getElementById("day").innerHTML = '0' + days ; 
        }else{
            document.getElementById("day").innerHTML = days ; 
        }

        if(hours < 10){
            document.getElementById("hour").innerHTML = '0' + hours ; 
        }else{
            document.getElementById("hour").innerHTML = hours ; 
        }

        if(minutes < 10){
            document.getElementById("minute").innerHTML = '0' + minutes ; 
        }else{
            document.getElementById("minute").innerHTML = minutes ; 
        }

        if(seconds < 10){
            document.getElementById("second").innerHTML = '0' + seconds ; 
        }else{
            document.getElementById("second").innerHTML = seconds ; 
        }

        if (t < 0) { 
                clearInterval(x); 
                document.getElementById("demo").innerHTML = "TIME UP"; 
                document.getElementById("day").innerHTML = '00'; 
                document.getElementById("hour").innerHTML ='00'; 
                document.getElementById("minute").innerHTML ='00' ;  
                document.getElementById("second").innerHTML = '00'; 
            } 
        }, 1000); 
    }

    render(){

        var workshop_time = this.state.workshopTime;
        var Hours = +workshop_time.substr(0, 2);
        var h = (Hours % 12) || 12;
        h = (h < 10)?("0"+h):h; 
        var ampm = Hours < 12 ? " (AM)" : " (PM)";
        workshop_time = h + workshop_time.substr(2, 3) + ampm;

        return(
            <div>
                <style jsx>
                    {`
                    .nextContainer{
                        background: black;
                        padding: 40px 0;
                    }
                    .iconSize{
                        display: block;
                        float: left;
                        width: 100px;
                        height: 100px;
                        border-radius: 70px;
                        background: #fff;
                        text-align: center;
                        padding-top: 36px;
                        margin-right: 15px;
                    }
                    .courseContent{
                        padding-left: 90px;
                    }
                    .courseContent h2{
                        font-size: 28px;
                        color: #fff;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        padding-top: 10px;
                        font-weight: 600;
                        letter-spacing: 2px;
                    }
                    .capIcon{
                        font-size: 30px;
                        color: #33a478;
                    }
                    .DHMSContainer{
                        padding-left: 10px;
                    }
                    .DHMSContainer .DHMSitem h4{
                        font-size: 50px;
                        letter-spacing: 4px;
                        font-weight: 600;
                    }
                    .DHMSContainer .DHMSitem{
                        min-width: 100px;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: #fff;
                        border-right: 1px solid #33A478;
                        text-align: center;
                        display: inline-block;
                    }
                    .DHMSContainer .DHMSitem:last-child{
                        border-right: none;
                    }
                    .dateTime{
                        font-size: 20px;
                        color: white;
                        letter-spacing: 3px;
                        font-weight: 600;
                    }
                    .timeUp{
                        position: absolute;
                        bottom: -45px;
                        text-align: center;
                        color: white;
                        left: 50%;
                        width: 100%;
                        font-size: 22px;
                        letter-spacing: 3px;
                        transform: translateX(-50%);
                    }
                    .cap{
                        margin-top: -25px;
                        max-width: 86px;
                        min-width: 76px;
                    }
                    @media only screen and (max-width: 600px){
                        .DHMSContainer{
                            padding-left: 0px;
                        }
                        .DHMSContainer .DHMSitem{
                            min-width: 90px;
                            font-size: 16px;
                            text-transform: uppercase;
                            color: #fff;
                            border-right: 1px solid #33A478;
                            text-align: center;
                            display: inline-block;
                        }
                    }
                    @media only screen and (max-width: 991px) and (min-width: 768px){
                        .DHMSContainer .DHMSitem{
                            min-width: 70px;
                            font-size: 16px;
                            text-transform: uppercase;
                            color: #fff;
                            border-right: 1px solid #33A478;
                            text-align: center;
                            display: inline-block;
                        }
                    }
                    `}
                </style>
                <div className="nextContainer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-6">
                                <div className="iconSize">
                                    <img src={cap} className="cap"/>
                                </div>
                                <div className="courseContent">
                                    <h2>{this.state.workshopName}</h2>
                                    <p className="dateTime">{dateFormat(this.state.workshopDate, "dd/mm/yyyy")} <span>{workshop_time}</span></p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <div className="DHMSContainer">
                                    <div className="DHMSitem">
                                        <h4 id="day"></h4>
                                        DAYS
                                    </div>
                                    <div className="DHMSitem">
                                        <h4 id="hour"></h4>
                                        HOURS
                                    </div>
                                    <div className="DHMSitem">
                                        <h4 id="minute"></h4>
                                        MINS
                                    </div>
                                    <div className="DHMSitem">
                                        <h4 id="second"></h4>
                                        SECS
                                    </div>
                                </div>
                                <p id="demo" className="timeUp"></p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default nextCourse;