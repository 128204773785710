import React, { Component } from "react";
// import enrollBanner from '../../assets/home-banner-3.jpg';
import countimage from '../../assets/countImage.jpg';
import ai from '../../assets/artificial.jpg';
import cyber from '../../assets/cyber.jpg';
import katalon from '../../assets/katalon.jpg';
import plsql from '../../assets/plsql.png';
import selenium from '../../assets/selenium.png';
import springboot from '../../assets/springboot.jpg';
import selejava from '../../assets/selejava.jpeg';
import devops from '../../assets/devops.png';
import corejava from '../../assets/corejava.jpeg';
import manualtesting from '../../assets/manualtesting.png';
import aws from '../../assets/aws.png';
import big from '../../assets/big.jpg';
import selepython from '../../assets/selepython.png';

class universityCourses extends Component {
    constructor(props){
        super(props);
        this.state = {
            studentCount : '',
            universityCount : '',
            workshopCount : '',
            subjectCount : ''
        }
    }

   componentDidMount(){
        
	    fetch("https://live.skillrary.com/live-courses/index.php/api/v1/university", {
	     method: 'GET',
	     headers: {'Content-Type':'application/json'},
	    })
	      .then(res => res.json())
	      .then(
	        (result) => {
              this.setState({
                    studentCount : result[0].student_count,
                    universityCount : result[0].university_count,
                    workshopCount : result[0].workshop_count,
                    subjectCount : result[0].subject_count
              })
	        },
	        (error) => {
	          this.setState({
	            isLoaded: true,
	            error
	          });
	        }
        )
    }

  render(){
       return (
    <div>
        <style jsx>{`
            .ourCourses{
                font-size: 32px;
                text-align: center;
                font-weight: 600;
                letter-spacing: 3px;
            }
            .ourCoursesPText{
                font-size: 14px;
                text-align: center;
            }
            .ourCourseImage{
                width: 100%;
                // height: 250px;
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
                padding-top: 90px;
                padding-bottom: 90px;
            }
            .ourCourseImage::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #000000ba;
                opacity: 0.9;
            }
            .courseContentContainer{
                position: relative;
                z-index: 1;
            }
            .collegeContentIcon{
                font-size: 48px;
                color: #fff;
            }
            .collegeContent{
                padding-left: 20px;
                display: inline-block;
                text-align: left;
            }
            .collegeContentIcon h2{
                color: white;
                font-weight: 600;
                font-size: 40px;
            }
            .collegeContent p {
                margin-bottom: 0;
                color: #fff;
                text-align: center;
                font-size: 20px;
            }
            .completeCard span{
                display: block;
            }

            .courseHeading{
                font-weight: 600;
            }

            .courseCard{
                border: 1px solid transparent;
            }

            .starIcon{
                font-size: 22px;
                letter-spacing: 3px;
                margin-top: -10px;
                color: #FFD700;
            }

            .inrAmount{
                font-size: 22px;
                font-weight: 600;
                margin-top: -10px;
            }

            .cardColMd3{
                display: inline-block;
            }
            .cardColMd3 img{
                width:100%;
                height:auto;
            }

            body .no-padding{
                padding-left: 0;
                padding-right: 0;
            }
            
            .carousel-control-prev{
                opacity: 5 !important;
            }
            .carousel-control-prev-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
            }

            .carousel-control-next-icon {
                opacity: 3;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
            }
            .moreDetailsBtn{
                padding: 10px;
                background: #33A478;
                border: 1px solid #33A478;
                border-radius: 35px;
                width: 45%;
                font-size: 18px;
            }
            .moreDetailsBtn:focus{
                outline: none;
            }
            @-webkit-keyframes swing
            {
                15%
                {
                    -webkit-transform: translateX(5px);
                    transform: translateX(5px);
                }
                30%
                {
                    -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
                } 
                50%
                {
                    -webkit-transform: translateX(3px);
                    transform: translateX(3px);
                }
                65%
                {
                    -webkit-transform: translateX(-3px);
                    transform: translateX(-3px);
                }
                80%
                {
                    -webkit-transform: translateX(2px);
                    transform: translateX(2px);
                }
                100%
                {
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }
            }
            @keyframes swing
            {
                15%
                {
                    -webkit-transform: translateX(5px);
                    transform: translateX(5px);
                }
                30%
                {
                    -webkit-transform: translateX(-5px);
                    transform: translateX(-5px);
                }
                50%
                {
                    -webkit-transform: translateX(3px);
                    transform: translateX(3px);
                }
                65%
                {
                    -webkit-transform: translateX(-3px);
                    transform: translateX(-3px);
                }
                80%
                {
                    -webkit-transform: translateX(2px);
                    transform: translateX(2px);
                }
                100%
                {
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }
            }
            .courseCard:hover img{
                -webkit-animation: swing 1s ease;
                animation: swing 1s ease;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
            }
            .specializedContainer{
                height: 390px;
            }
            @media only screen and (max-width: 600px){
                .moreDetailsBtn{
                    background: #33A478;
                    padding: 10px;
                    width: 70%;
                    border: 1px solid #33A478;
                    border-radius: 20px;
                    font-size: 18px;
                }
                .specializedContainer{
                    height: auto;
                }
            }
            @media only screen and (min-width: 768px) and (max-width: 1023px){
                .moreDetailsBtn{
                    background: #33A478;
                    padding: 10px;
                    width: 70%;
                    border: 1px solid #33A478;
                    border-radius: 20px;
                    font-size: 18px;
                }
                .specializedContainer{
                    height: auto;
                }
            }
        `}
      </style>
      <h3 className="ourCourses">WE ARE SPECIALIZED IN</h3>
            <p className="ourCoursesPText">Explore your options for endless possibilites</p><br/>
            <div id="demo3" class="carousel slide specializedContainer" data-ride="carousel" data-interval="0">
                <div class="container carousel-inner no-padding">
                    <div class="carousel-item active" align="center" id="carouselBgColor">
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                                <a href="https://skillrary.com/search?q=artificial+intelligence"><img src={ai} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">ARTIFICIAL INTELLIGENCE</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(35)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                            <a href="https://skillrary.com/search?q=cyber+security"><img src={cyber} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">CYBERSECURITY</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(35)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                            <a href="https://skillrary.com/search?q=katalon"><img src={katalon} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">KATALON STUDIO</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(35)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button>              */}
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" align="center" id="carouselBgColor">
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                            <a href="https://skillrary.com/search?q=plsql"><img src={plsql} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">PL-SQL</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(16)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                            <a href="https://skillrary.com/search?q=advance+selenium"><img src={selenium} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">ADVANCED SELENIUM</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(40)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                            <a href="https://skillrary.com/search?q=spring+boot"><img src={springboot} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">SPRING BOOT SELENIUM USING JAVA</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-star-half"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>4.3(56)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button>              */}
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item" align="center" id="carouselBgColor">
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                            <a href="https://skillrary.com/search?q=selenium+using+java"><img src={selejava} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">SELENIUM USING JAVA</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(20)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                                <a href="https://skillrary.com/search?q=devops"><img src={devops} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">DEVOPS</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(33)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                                <a href="https://skillrary.com/search?q=core+java"><img src={corejava} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">CORE JAVA</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(25)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button>              */}
                            </div>
                        </div>
                    </div>
                    
                    <div class="carousel-item" align="center" id="carouselBgColor">
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                            <a href="https://skillrary.com/search?q=manual+testing"><img src={manualtesting} style={{ width: '100%',  height: '215px' }}/></a><br />
                                <p><h4 className="courseHeading">MANUAL TESTING</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-star-half"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>4.6(128)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                                <a href="https://skillrary.com/search?q=selenium+using+python"><img src={selepython} style={{ width: '100%', height: '215px' }}/></a><br />
                                <p><h4 className="courseHeading">SELENIUM USING PYTHON</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>5.0(25)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 cardColMd3"> 
                            <div className="card courseCard" style={{ marginBottom: "10px" }} >
                                <a href="https://skillrary.com/search?q=big+data+"><img src={big} style={{ width: '100%' }}/></a><br />
                                <p><h4 className="courseHeading">BIG DATA AND HADOOP</h4></p>    
                                <p className="starIcon">
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-enable fa-star"></i>
                                    <i className="fa fa-star-half"></i>&nbsp;<span style={{ letterSpacing: '0px', color: 'black' }}>4.6(49)</span>
                                </p> 
                                {/* <p className="inrAmount">INR 500</p>
                                <button className="moreDetailsBtn">More Details</button>              */}
                            </div>
                        </div>
                    </div>
                </div>

                <a class="carousel-control-prev" href="#demo3" data-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#demo3" data-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </a>

            </div><br/>
            <div className="ourCourseImage" style={{  backgroundImage: "url(" + countimage + ")"}}>
                <div className="container courseContentContainer">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2 col-sm-6" align="center">
                            <div className="collegeContentIcon">
                                <h2>{this.state.universityCount + '+'}</h2>
                            </div>
                            <div className="collegeContent">
                                <p>UNIVERSITIES</p>
                            </div>
                        </div>
                        <div className="col-lg-3  col-sm-6" align="center">
                            <div className="collegeContentIcon">
                                <h2>{this.state.studentCount  + '+'}</h2>
                            </div>
                            <div className="collegeContent">
                                <p>STUDENTS</p>
                            </div>
                        </div>
                        <div className="col-lg-3  col-sm-6" align="center">
                            <div className="collegeContentIcon">
                                <h2>{this.state.workshopCount + '+'}</h2>
                            </div>
                            <div className="collegeContent">
                                <p>WORKSHOPS</p>
                            </div>
                        </div>
                        <div className="col-lg-2  col-sm-6" align="center">
                            <div className="collegeContentIcon">
                                <h2>{this.state.subjectCount + '+'}</h2>
                            </div>
                            <div className="collegeContent">
                                <p>SUBJECTS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);
  }
}
 

export default universityCourses;