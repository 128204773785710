import React, { Component } from 'react';
import enrollBanner from '../../assets/home-banner-3.jpg';
import img1 from '../../assets/img2.png';
import img2 from '../../assets/img1.png';

class universityEvents extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .ourEvents{
                        font-size: 32px;
                        text-align: center;
                        font-weight: 600;
                        letter-spacing: 3px;
                    }
                    .ourEventsPText{
                        font-size: 16px;
                        text-align: center;
                        font-weight: 600;
                        letter-spacing: 4px;
                    }
                    .ourImageEvents{
                        width: 100%;
                    }
                    .eventHeading{
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .eventPlace{
                        font-size: 12px;
                    }
                    .cardColMd3{
                        display: inline-block;
                    }
                    .cardColMd3 img{
                        width:100%;
                        height:auto;
                    }
        
                    body .no-padding{
                        padding-left: 0;
                        padding-right: 0;
                    }
                    
                    .carousel-control-prev{
                        opacity: 5 !important;
                    }
                    .carousel-control-prev-icon {
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
                    }
        
                    .carousel-control-next-icon {
                        opacity: 3;
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
                    }

                    .eventCard{
                        margin: 0 auto;
                        border: 1px solid transparent;
                    }
                    .knowMoreBtn{
                        background: #33A478;
                        padding: 10px;
                        width: 30%;
                        border: 1px solid #33A478;
                        border-radius: 20px;
                        font-size: 18px;
                    }
                    .knowMoreBtn:focus{
                        outline: none;
                    }

                    // .zoom-effect-container {
                    //     float: left;
                    //     position: relative;
                    //     width: 100%;
                    //     height: 315px;
                    //     margin: 0 auto;
                    //     overflow: hidden;
                    // }
                    
                    // .image-card {
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    // }
                    // .zoom-effect-container:hover .image-card img {
                    //     -webkit-transform: scale(1.08);
                    //     transform: scale(1.08);
                    // }
                    // .image-card img {
                    //     -webkit-transition: 0.4s ease;
                    //     transition: 0.4s ease;
                    // }
                    .imageContainer {
                        margin: 0 auto;
                        overflow: hidden;
                    }
                    .imageContainer img {
                        max-width: 100%;
                        transition: transform 0.4s ease-in-out;
                    }
                    .imageContainer:hover img {
                        transform: scale(1.2);
                    }
                    @media only screen and (max-width: 600px){
                        .knowMoreBtn{
                            background: #33A478;
                            padding: 10px;
                            width: 43%;
                            border: 1px solid #33A478;
                            border-radius: 20px;
                            font-size: 18px;
                        }
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1023px){
                        .knowMoreBtn{
                            background: #33A478;
                            padding: 10px;
                            width: 43%;
                            border: 1px solid #33A478;
                            border-radius: 20px;
                            font-size: 18px;
                        }
                    }
                    `}
                </style>
                    <h3 className="ourEvents">OUR EVENTS</h3>
                    <p className="ourEventsPText">LOOK FOR WHAT NEW WE HAVE TO OFFER YOU</p><br/>
                    <div id="demo" class="carousel slide" data-ride="carousel" data-interval="0">
                        <div class="container carousel-inner no-padding">
                            <div class="carousel-item active" align="center" id="carouselBgColor">
                                <div className="col-lg-6 col-md-6 col-sm-6 cardColMd3"> 
                                    <div className="card eventCard" style={{ marginBottom: "10px" }} >
                                        {/* <div className="zoom-effect-container">
                                            <div className="image-card">
                                                <img src={enrollBanner} style={{ width: '100%' }}/><br />
                                            </div>
                                        </div> */}

                                        <div className="imageContainer">
                                            <img src={img1} style={{ width: '100%' }}/>
                                        </div><br />
                                        <p><h4 className="eventHeading">ACHARAYA INSTITUTE OF TECHNOLOGY</h4></p>    
                                        <p className="eventPlace">Bengaluru</p>
                                        {/* <button className="knowMoreBtn">Know More</button> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 cardColMd3"> 
                                    <div className="card eventCard" style={{ marginBottom: "10px" }} >
                                        {/* <div className="zoom-effect-container">
                                            <div className="image-card">
                                                <img src={enrollBanner} style={{ width: '100%' }}/><br />
                                            </div>
                                        </div> */}

                                        <div className="imageContainer">
                                            <img src={img2} style={{ width: '100%' }}/>
                                        </div><br />
                                        <p><h4 className="eventHeading">SAMBHRAM INSTITUTE OF TECHNOLOGY</h4></p>    
                                        <p className="eventPlace">Bengaluru</p>
                                        {/* <button className="knowMoreBtn">Know More</button> */}
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    
                        {/* <a className="carousel-control-prev" href="" data-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                        </a>
                        <a className="carousel-control-next" href="" data-slide="next">
                            <span class="carousel-control-next-icon"></span>
                        </a> */}

                    </div><br/>
            </div>

        )
    }
}

export default universityEvents;