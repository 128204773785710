import React, { Component } from 'react';
import methods from '../../assets/methods.jpg';

class universityEnrollment extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .enrollImage{
                        width: 100%;
                        // height: 500px;
                        position: relative;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                    // .enrollImage::after {
                    //     position: absolute;
                    //     content: '';
                    //     width: 100%;
                    //     height: 100%;
                    //     left: 0;
                    //     top: 0;
                    //     background: #000;
                    //     opacity: .8;
                    // }
                    .enrollContentContainer{
                        position: relative;
                        z-index:1;
                    }
                    .enrollmentText{
                        font-size: 38px;
                        color: white;
                        text-transform: uppercase;
                        padding: 20px;
                        background: black;
                    }
                    .paddingForContent{
                        padding: 60px;
                    }
                    .enrollPTagContent{
                        font-size: 18px;
                        color: white;
                    }
                    .enrollList{
                        padding-top: 20px;
                    }
                    .enrollListItem .enrollNumber {
                        position: absolute;
                        width: 80px;
                        height: 80px;
                        left: 0;
                        top: 0;
                        text-align: center;
                        border-radius: 50px;
                        background: #33A478;
                        font-size: 16px;
                        font-weight: 500;
                        padding-top: 30px;
                        color: white;
                    }
                    .enrollListItem{
                        padding-left: 70px;
                        position: relative;
                        padding-top: 133px;
                    }
                    .enrollListItem::after {
                        height: 45px;
                        position: absolute;
                        content: '';
                        /* height: calc(100% - 109px); */
                        left: 40px;
                        top: 83px;
                        border-left: 1px dashed white;
                    }
                    .enrollListItem:last-child::after{
                        border-left: none;
                    }
                    .enrollMiniHeadingText{
                        font-size: 34px;
                        color: white;
                        font-weight: 600;
                    }
                    .enrollText{
                        font-size: 14px;
                        color: white;
                    }
                    .enrollSideImage{
                        width: 100%;
                        height: 390px;
                    }
                    .methodsText{
                        margin-top: -119px;
                        position: absolute;
                        padding-left: 30px;
                    }
                    @media only screen and (max-width: 600px){
                        .paddingForContent {
                            padding: 10px;
                        }
                        .enrollMiniHeadingText {
                            font-size: 27px;
                            color: white;
                            font-weight: 600;
                        }
                    }
                    `}
                </style>
                <div className="enrollImage paddingForContent" style={{  backgroundImage: "url(" + methods + ")"}}>
                    <div className="container enrollContentContainer">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-6">
                                <div>
                                    <h2 className="enrollmentText">METHODS OF TRAINING</h2>
                                    <p className="enrollPTagContent">Get started with us to explore the exciting</p>
                                </div>
                                <div className="enrollList">
                                    <div className="enrollListItem">
                                        <span className="enrollNumber">1</span>
                                        <div className="methodsText">
                                            <h5 className="enrollMiniHeadingText">INSTRUCTOR LED LIVE TRAINING</h5>
                                        </div>
                                    </div>
                                    <div className="enrollListItem">
                                        <span className="enrollNumber">2</span>
                                        <div className="methodsText">
                                            <h5 className="enrollMiniHeadingText">SELF PACED TRAINING</h5>
                                        </div>
                                    </div>
                                    <div className="enrollListItem">
                                        <span className="enrollNumber">3</span>
                                        <div className="methodsText">
                                            <h5 className="enrollMiniHeadingText">CLASSROOM TRAINING</h5>
                                        </div>
                                    </div>
                                    <div className="enrollListItem">
                                        <span className="enrollNumber">4</span>
                                        <div className="methodsText">
                                            <h5 className="enrollMiniHeadingText">SKILLX WORKSHOP</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 offset-lg-1">
                                <img src={enrollBanner} className="enrollSideImage"/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default universityEnrollment;