import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import University from './App';

class SKillraryRoute extends Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route path="/" exact strict component={University} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
export default SKillraryRoute;