import React, { Component } from 'react';
import banner from '../../assets/universitybanner.png';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Swal from 'sweetalert2';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class animationSlider extends Component{
    constructor(props){
        super(props);
        this.state = {
            openUniversity: false,
            name: '',
            email: '',
            contact_number: '',
            university: '',
            message: '',
            errors: {}
        }
        this.onSubmitUniversity = this.onSubmitUniversity.bind(this)
    }

    onOpenModalUniversity = () => {
		this.setState({ openUniversity: true });
	};
	 
	onCloseModalUniversity = () => {
        this.setState({ openUniversity: false });
        this.setState({ name:'',email:'',university:'',contact_number:'',message:'',errors:''})
    };

    onSubmitUniversity(e){
        e.preventDefault();
        let errors = {};
		let formIsValid = false;
		if(!this.state.name){
            formIsValid = true
            errors['name'] = "This field is required"
		}
		else if(!this.state.email){
			formIsValid = true
			errors['email'] = "This field is required"
		}
		else if(!this.state.university) {
			formIsValid = true
			errors['university'] = "This field is required"
        }
        else if(!this.state.contact_number){
			formIsValid = true
			errors['contact_number'] = "This field is required"
        }
        else if(this.state.contact_number.length < 10){
			formIsValid = true
			errors['contact_number'] = "Minimum should be 10 characters"
		}
		else if(this.state.contact_number.length > 10){
			formIsValid = true
			errors['contact_number'] = "Maximum should be 10 characters"
        }
		else if(!this.state.message){
			formIsValid = true
			errors['message'] = "This field is required"
		}
	
		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
        }

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/university-getting-started", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			
			body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                university: this.state.university,
                contact_number: this.state.contact_number,
                message: this.state.message,
			})
		})
      .then(res => res.json())
      .then((result) => {
		//   console.log('rest', result.status);
		  if(result.status == 200){
            Swal.fire({
            type: 'success',
            title: 'Thank you, Your request is submitted successfully',
            showConfirmButton: false,
            timer: 2500
            })
            this.setState({ name:'',email:'',university:'',contact_number:'',message:''})
            this.onCloseModalUniversity()
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
    }
    
    render(){
        const { openUniversity } = this.state
        return(
            <div>
                <style jsx>
                    {`
                    .errTextContact{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        left: -75px;
                        top: 49px;
                        position: absolute;
                        z-index: 1;
                    }
                    .getStartedBtnModal{
                        background: #33A478;
                        padding: 5px;
                        border: 1px solid #33a478;
                        font-size: 17px;
                        border-radius: 20px;
                        font-weight: 500;
                        width: 20%;
                    }
                    .getStartedBtnModal:focus{
                        outline: none;
                    }
                    .inputField{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        padding-left:5px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .inputFieldMsz{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        padding-left:5px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .modalWidth{
                        width: 600px !important;
                        margin-top: 50px;
                    }
                    .exploringText{
                        font-weight: 600;
                        font-size: 40px;
                        color: black;
                        letter-spacing: 3px;
                    }
                    .bannerPtext{
                        font-size: 24px;
                        color: black;
                    }
                    .getStartedBtn{
                        background: #33A478;
                        padding: 10px;
                        border: 1px solid #33a478;
                        font-size: 18px;
                        border-radius: 20px;
                        font-weight: 600;
                    }
                    .getStartedBtn:focus{
                       outline: none;
                    }
                    .bannerContent{
                        position: absolute;
                        top: 40%;
                        right: 40px;
                        text-align: center;
                        width: 60%;
                    }
                    .w-100{
                        height: 800px;
                    }
                    textarea{
                        resize: none;
                    }
                    .sessionHeading{
                        font-size: 26px;
                        font-weight: 600;
                    }
                    @media only screen and (max-width: 600px){
                        .w-100{
                            margin-top: 94px;
                            height: auto;
                        }
                        .bannerContent{
                            position: absolute;
                            top: 40%;
                            right: 0px;
                            text-align: center;
                            width: 100%
                        }
                        .exploringText{
                            font-weight: 600;
                            font-size: 14px;
                            color: black;
                            letter-spacing: 3px;
                        }
                        .bannerPtext{
                            font-size: 12px;
                            color: black;
                        }
                        .getStartedBtn{
                            background: #33A478;
                            padding: 3px;
                            border: 1px solid #33a478;
                            font-size: 9px;
                            border-radius: 20px;
                            font-weight: 600;
                        }
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1023px){
                        .bannerContent{
                            position: absolute;
                            top: 19%;
                            right: 0px;
                            text-align: center;
                            width: 100%;
                        }
                        .exploringText{
                            font-weight: 600;
                            font-size: 28px;
                            color: black;
                            letter-spacing: 3px;
                        }
                        .bannerPtext{
                            font-size: 24px;
                            color: black;
                        }
                    }
                    @media only screen and (min-width: 1024px) and (max-width:1366px){
                        .bannerContent {
                            position: absolute;
                            top: 22%;
                            right: 40px;
                            text-align: center;
                            width: 60%;
                        }
                    }
                    `}
                </style>
                <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel" data-interval="2000">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <div style={{ position: 'relative', background: 'white', height: '800px' }}>
                            <LazyLoadImage class="d-block w-100" style={{ width: '100%' }} src={banner}
                                alt="First slide"/>
                        </div>
                        <p className="bannerContent">
                            <h2 className="exploringText">EXPLORING EDUCATION CLASSROOM BEYOND</h2>
                            <p className="bannerPtext">Education is not just about going to school and getting a degree. It's about widening your knowledge and absorbing the truth about life for knowledge is power.</p>
                            <button className="getStartedBtn" onClick={this.onOpenModalUniversity}>GET STARTED</button>
                        </p>
                        <Modal open={openUniversity} onClose={this.onCloseModalUniversity} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
                            <div className="modalWidth" >
                                <h4 className="sessionHeading" style={{ marginTop: '-50px',textAlign: 'center' }}>Request for a Session</h4>
                                <div className="container" align="center">
                                    <form onSubmit={this.onSubmitUniversity}>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text" name="name" className="inputField" 
                                                    placeholder="Name" onChange={(v) => this.setState({ name: v.target.value, errors: '' })} value={this.state.name} autoComplete="off"/>
                                                    <span className="errTextContact">{this.state.errors.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="email" name="email" className="inputField" 
                                                    placeholder="Email Address" onChange={(v) => this.setState({ email: v.target.value, errors: '' })} value={this.state.email} autoComplete="off" />
                                                    <span className="errTextContact">{this.state.errors.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text"
                                                    className="inputField" 
                                                    placeholder="University" onChange={(v) => this.setState({ university: v.target.value, errors: '' })} value={this.state.university} name="university" id="batchInput"
                                                    autoComplete="off"/>
                                                    <span className="errTextContact">{this.state.errors.university}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="number" name="contact_number" className="inputField" 
                                                    placeholder="Contact Number" onChange={(v) => this.setState({ contact_number: v.target.value, errors: '' })} value={this.state.contact_number} autoComplete="off" />
                                                    <span className="errTextContact">{this.state.errors.contact_number}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <textarea type="text" name="mobile_classroom" className="inputField" 
                                                    placeholder="Message" onChange={(v) => this.setState({ message: v.target.value, errors: '' })} value={this.state.message} autoComplete="off"
                                                    />
                                                    <span className="errTextContact">{this.state.errors.message}</span>
                                                </div>
                                            </div>
                                        </div><br/>
                                        <div align="center" style={{ justifyContent: "center" }}>
                                            <button className="getStartedBtnModal">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
						</Modal>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default animationSlider;