import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Categories extends Component{

  // componentDidMount(){
  //   $('.firstList').hover(function() {
  //     $(".firstList").removeClass("liactive");
  //     $(this).addClass("liactive");
  // });
  // }

  constructor(props) {
    super(props);
    this.state = {
      course: []
    }
    this.getCourse = this.getCourse.bind(this);
    this.createCategory = this.createCategory.bind(this);
  }

  componentDidMount() {
    this.getCourse();
  }

  getCourse() {
    fetch("https://live.skillrary.com/live-courses/index.php/api/v1/all-catogories-courses", {
     method: 'GET',
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log('res carousel', result);
          this.setState({
            course: result.result,
          });   
          console.log('courseList-=======================',this.state.course)
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  createCategory(category) {

    console.log('aaf', category)

    var subCategory = [];

     Object.keys(category.courses).map(function(keyName, keyIndex) {
        console.log('adfc',keyName, category.courses[keyName].id);
       
        subCategory.push(
            <li className="secondList">
            <Link to={{
                      pathname: '/CourseInfo',
                      courseId:  category.courses[keyName].id
                    }}>

                {category.courses[keyName].course_title}    
            </Link>
            </li>
        );
      })

    return(
       <li className="firstListItem">
          <a href="#">{category.category_name}</a>
          <div>
            <ul className="courseSubList">
                {subCategory}
            </ul>
          </div>
      </li>
    )

  }

  render(){

    const categories = this.state.course;

    var categoryData = [];
    console.log('ss', typeof(categories))
        if (categories.length > 0) {

            var self = this;
            Object.keys(categories).map(function(keyName, keyIndex) {
              console.log('adf',keyName, categories[keyName]);
             
              categoryData.push(self.createCategory(categories[keyName]));
              
            })
        }
 
    return(
    <div>
    <style jsx>
    {`
    // .liactive{
    //   background: black;
    //   color: white;
    // }
      #nav {
          position: absolute;
          display: inline-block;
          font-family: Helvetica, Arial, sans-serif;
          font-size: 12px;
          color: #707070;
          line-height: 18px;    
          margin-bottom: 20px;
          background-color: #fafafa;
          right: 0;
          left: 80px;
          width: 300px;
          font-size: 13px;
          font-weight: 400;
          height: auto;
          box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 38px 82px 0px rgba(0,0,0,0.18);
          transition: all 0.3s ease-in-out 0s;
        }

      #nav ul 
      {
        margin: 0px;
        padding: 0px;   
        position: relative;
        background-color: #ffffff;
      }

      #nav ul li {
          margin: 0px;
          padding: 1px 5px 1px 5px;
          list-style: none;
          white-space: nowrap;
          display: block;
          min-width: 80px;
      }
      #nav .courseList .firstListItem:hover {
          background-color: #fafafa;
          color: #33A478;
          border-left: 4px solid #33A478;
      }
      #nav ul li a:hover{
          color: #33A478;
          font-weight: 600;
      }
      #nav ul li a {
          text-decoration: none;
          color: #707070;
          display: block;
          font-size: 14px;
          padding: 0 42px 0 0;
          display: block;
          color: #676767;
          text-decoration: none;
          line-height: 40px;
          position: relative;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          border-bottom: 1px solid #eaeaea;
          margin: 0 25px;
      }
      #nav ul .firstList {
          display: block;
          position: absolute;
          left: 100%;
          top: 0px; 
          float: left;
          max-height: 100px;
      }

      #nav ul div{
        display: none;
        position: absolute;
        left: 100%;
        top: 0px;
        float: left;
        overflow: hidden;
        //max-height: 500px;
      }

      #nav ul div ul {
        float: left;
        display: inline;
      }

      #nav ul li ul li{
        min-width: 150px; 
      }

      #nav ul li:hover > div {
          display: block;
          background-color: #fafafa;  
      }
      .courseList{
        width: 300px;
        height: auto;
        background-color: #ffffff !important;
      }
      .courseSubList{
        width: 300px;
        height: auto;
        background-color: #fafafa !important;
        //overflow-y: scroll;
      }
      @media only screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2){ 
           #nav ul li a {
              text-decoration: none;
              color: #707070;
              display: block;
              font-size: 14px;
              padding: 0px;
              display: block;
              color: #676767;
              text-decoration: none;
              line-height: 40px;
              position: relative;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              border-bottom: 1px solid #eaeaea;
              margin: 0 25px;
          }
          #nav ul li {
              margin: 0px;
              padding: 0px;
              list-style: none;
              white-space: nowrap;
              display: block;
              min-width: 80px;
          }
          .courseList{
            width: 165px !important;
            height: 500px;
            background-color: #ffffff !important;
          }
          .courseSubList{
            width: 165px !important;
            background-color: #fafafa !important;
          }
         #nav {
            position: absolute;
            display: inline-block;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 12px;
            color: #707070;
            line-height: 18px;    
            margin-bottom: 20px;
            background-color: #fafafa;
            right: 0;
            margin-left: -70px;
            width: 330px !important;
            font-size: 13px;
            font-weight: 400;
            height: 500px;
            box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 38px 82px 0px rgba(0,0,0,0.18);
            transition: all 0.3s ease-in-out 0s;
          }   
      }
      @media only screen and (max-width: 600px) {
          #nav ul li a {
              text-decoration: none;
              color: #707070;
              display: block;
              font-size: 14px;
              padding: 0px;
              display: block;
              color: #676767;
              text-decoration: none;
              line-height: 40px;
              position: relative;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              border-bottom: 1px solid #eaeaea;
              margin: 0 25px;
          }
          #nav ul li {
              margin: 0px;
              padding: 0px;
              list-style: none;
              white-space: nowrap;
              display: block;
              min-width: 80px;
          }
          .courseList{
            width: 210px;
            height: 500px;
            background-color: #ffffff !important;
          }
          .courseSubList{
            width: 210px;
            background-color: #fafafa !important;
          }
         #nav {
            position: absolute;
            display: inline-block;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 12px;
            color: #707070;
            line-height: 18px;    
            margin-bottom: 20px;
            background-color: #fafafa;
            right: 0;
            margin-left: -70px;
            width: 420px;
            font-size: 13px;
            font-weight: 400;
            height: 500px;
            box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 38px 82px 0px rgba(0,0,0,0.18);
            transition: all 0.3s ease-in-out 0s;
          }
        }
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
          #nav {
            position: absolute;
            display: inline-block;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 12px;
            color: #707070;
            line-height: 18px;    
            margin-bottom: 20px;
            background-color: #fafafa;
            right: 0;
            left: 30px;
            width: 600px;
            font-size: 13px;
            font-weight: 400;
            height: 500px;
            box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 38px 82px 0px rgba(0,0,0,0.18);
            transition: all 0.3s ease-in-out 0s;
          }
        }
      
    `}
    </style>
      
        <div id="nav">
          <ul className="courseList">
            {categoryData}
            {/*
              <li className="firstListItem">
                  <a href="#">DevOps</a>
                  <div class="firstList">
                    <ul className="courseSubList">
                        <li className="secondList"><a href="#">DevOps</a></li>
                        <li className="secondList"><a href="#">Sub Item 1.2 Can be long</a></li>
                        <li className="secondList"><a href="#">Sub Item 1.3</a></li>
                        <li className="secondList"><a href="#">Sub Item 1.4</a></li>
                    </ul>
                  </div>
              </li>
              <li className="firstListItem">
                  <a href="#">Manual Testing</a>
                  <div>
                    <ul className="courseSubList">
                        <li className="secondList"><a href="#">Java for Selenium</a></li>
                        <li className="secondList"><a href="#">Python Selenium</a></li>
                        <li className="secondList"><a href="#">iOS Development</a></li>
                        <li className="secondList"><a href="#">Mobile Ui Design Essentials And Ui Design Patterns</a></li>
                        <li className="secondList"><a href="#">NativeScript for Mobile App Development</a></li>
                        <li className="secondList"><a href="#">OpenGL</a></li>
                        <li className="secondList"><a href="#">PhoneGap</a></li>
                        <li className="secondList"><a href="#">Sencha Touch</a></li>
                        <li className="secondList"><a href="#">Xamarin - Mobile App Development</a></li>
                    </ul>
                  </div>
              </li>
              
              <li className="firstListItem">
                  <a href="#">Big Data</a>
                  <div>
                    <ul className="courseSubList">
                        <li className="secondList"><a href="#">Big Data and Hadoop</a></li>
                        
                        <li className="secondList"><a href="#">Advanced Java</a></li>
                        <li className="secondList"><a href="#">Akka With Java</a></li>
                        <li className="secondList"><a href="#">Akka With Scala</a></li>
                        <li className="secondList"><a href="#">Angular 2.0</a></li>
                        <li className="secondList"><a href="#">Angular 4</a></li>
                        <li className="secondList"><a href="#">Angular 8</a></li>
                        <li className="secondList"><a href="#">Angular Material</a></li>
                        <li className="secondList"><a href="#">ASP.NET using C#</a></li>
                        <li className="secondList"><a href="#">Aurelia</a></li>
                        <li className="secondList"><a href="#">Backbone JS</a></li>
                        <li className="secondList"><a href="#">Bootstrap</a></li>
                        <li className="secondList"><a href="#"></a></li>
                        <li className="secondList"><a href="#"></a></li>
                    </ul>
                  </div>
              </li>
              <li className="firstListItem">
                  <a href="#">Artificial Intelligence</a>
                  <div>
                    <ul className="courseSubList">
                        <li className="secondList"><a href="#">Artificial Intelligence</a></li>
                        
                        <li className="secondList"><a href="#">Advanced Java</a></li>
                        <li className="secondList"><a href="#">Akka With Java</a></li>
                        <li className="secondList"><a href="#">Akka With Scala</a></li>
                        <li className="secondList"><a href="#">Angular 2.0</a></li>
                        <li className="secondList"><a href="#">Angular 4</a></li>
                        <li className="secondList"><a href="#">Angular 8</a></li>
                        <li className="secondList"><a href="#">Angular Material</a></li>
                        <li className="secondList"><a href="#">ASP.NET using C#</a></li>
                        <li className="secondList"><a href="#">Aurelia</a></li>
                        <li className="secondList"><a href="#">Backbone JS</a></li>
                        <li className="secondList"><a href="#">Bootstrap</a></li>
                        <li className="secondList"><a href="#"></a></li>
                        <li className="secondList"><a href="#"></a></li>
                    </ul>
                  </div>
              </li>
             
              <li className="firstListItem">
                  <a href="#">Database</a>
                  <div>
                    <ul className="courseSubList">
                        <li className="secondList"><a href="#">Cassandra</a></li>
                        <li className="secondList"><a href="#">CouchDB</a></li>
                        <li className="secondList"><a href="#">HBase</a></li>
                        <li className="secondList"><a href="#">MariaDB Administration</a></li>
                        <li className="secondList"><a href="#">MariaDB For Developers</a></li>
                        <li className="secondList"><a href="#">Master DB2</a></li>
                        <li className="secondList"><a href="#">Master Neo4J Graph Database</a></li>
                        <li className="secondList"><a href="#">MongoDB</a></li>
                        <li className="secondList"><a href="#">MongoDB Administration</a></li>
                        <li className="secondList"><a href="#">MySQL</a></li>
                        <li className="secondList"><a href="#">PostgreSQL</a></li>
                        <li className="secondList"><a href="#">PouchDB</a></li>
                        <li className="secondList"><a href="#"></a></li>
                        <li className="secondList"><a href="#"></a></li>
                    </ul>
                  </div>
              </li>
              */}
          </ul>
      </div>
    </div>
    )
  }
}

export default Categories;