import React, { Component } from 'react';
import flexible from '../../assets/flexible.png';
import cloud from '../../assets/server.png';
import compiler from '../../assets/compiler.png';
import placements from '../../assets/placement.png';
import projects from '../../assets/projects.png';
import skillx from '../../assets/skillx.png';
import grooming from '../../assets/grooming.png';
import assessment from '../../assets/assessment.png';
import intern from '../../assets/intern.png';

class universityService extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .ourServices{
                        font-size: 32px;
                        text-align: center;
                        font-weight: 600;
                        letter-spacing: 3px;
                    }
                    .serviceContent{
                        font-size: 16px;
                        text-align: center;
                    }
                    .serviceIconInside{
                        float: left;
                        font-size: 30px;
                        width: 45px;
                        height: 47px;
                    }
                    .serviceIcon{
                        width: 75px;
                        height: 80px;
                        vertical-align: text-top;
                    }
                    .artStudio{
                        font-size: 22px;
                        font-weight: 600;
                    }
                    .serviceText{
                        padding-left: 90px;
                    }
                    .serviceText p{
                        font-size: 15px;
                    }
                    `}
                </style>
                <div className="container">
                    <h3 className="ourServices">OUR SERVICES</h3>
                    <p className="serviceContent">TO PROVIDE A BETTER FUTURE</p><br/><br/>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={flexible} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">FLEXIBLE SCHEDULE</h4>
                                <p>Customised learnings scheduled at your disposal to give you a holistic development</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6"  style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={cloud} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">CLOUD LABS</h4>
                                <p>Access to our extensive cloud labs services powered by programming languages, Bucket technology, Microsoft labs etc.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={compiler} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">COMPILERS</h4>
                                <p>Our all-in-one compilers enabled with various programming languages to give you hassle-free learning</p>
                            </div>
                        </div>
                
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={projects} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">Projects</h4>
                                <p>Learn to build live projects as required in the industry coupled with hands-on experience</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={skillx} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">SKILLX WORKSHOP</h4>
                                <p>Benefit from our specialized workshops aimed at improving your hands-on experience</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={placements} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">PLACEMENT ASSISTANCE</h4>
                                <p>We prepare you to be job-ready with our specialized mock interviews, resume assistance etc.</p>
                            </div>
                        </div>


                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={grooming} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">GROOMING</h4>
                                <p>Directed to bring out the 'Confident Yourself' to work independently in the ever increasing competition</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={intern} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">INTERNSHIP</h4>
                                <p>A time to learn new tools, gain experience and build confidence to make you industry ready</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: '15px' }}>
                            <div className="serviceIconInside">
                                <img src={assessment} className="serviceIcon"/>
                            </div>
                            <div className="serviceText">
                                <h4 className="artStudio">ASSESSMENT</h4>
                                <p>Customized assessments to keep a track of your performance metrics.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default universityService;